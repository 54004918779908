<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>修改菜单</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">系统</a></li>
        <li><a href="javascript:;">菜单</a></li>
        <li>修改</li>
      </ol>
    </div>


    <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>

    <a-row :gutter="20">
      <!-- <a-col :span="4">
        <a-card style="height:70vh; overflow-y:scroll;">
          <a-directory-tree
            :tree-data="menus"
            :selected-keys="menuKeys"
            default-expand-all
            show-line
            :default-expanded-keys="expandedKeys"
            :replaceFields="{key:'id', title: 'name'}"
            @select="onSelect"
          />
        </a-card>
      </a-col> -->
      <a-col :span="24">
          <a-card title="菜单信息">
            <a-form-model-item prop="id" label="编号">
              <a-input type="text" v-model="item.id" disabled />
            </a-form-model-item>

            <a-form-model-item prop="parent_id" label="上级">
              <a-tree-select
                :treeData="menus"
                :replaceFields="{key:'id', title: 'name', value: 'id'}"
                show-search
                v-model="form.data.parent_id"
                placeholder="选择上级菜单"
                  tree-default-expand-all
                />
            </a-form-model-item>


            <a-form-model-item prop="role_ids" label="角色可见">
              <a-select
                :options="roles"
                mode="multiple"
                v-model="form.data.role_ids"
                placeholder="选择角色"
              />
            </a-form-model-item>


            <a-form-model-item prop="index" label="排序">
              <a-input type="number" v-model="form.data.index" placeholder="排序，从小到大" />
            </a-form-model-item>

              <a-form-model-item prop="icon" label="图标">
              <a-input type="text" v-model="form.data.icon" placeholder="图标" />
            </a-form-model-item>
            <a-form-model-item prop="name" label="名称">
              <a-input type="text" v-model="form.data.name" placeholder="菜单名称" />
            </a-form-model-item>
            <a-form-model-item prop="target" label="目标">
              <a-input type="text" v-model="form.data.target" placeholder="链接目标" />
            </a-form-model-item>
            <a-form-model-item prop="remark" label="备注">
              <a-textarea v-model="form.data.remark" :rows=4  placeholder="备注" />
            </a-form-model-item>

          </a-card>



      </a-col>
    </a-row>

    <div class="submit">
      <a-button type="primary" html-type="submit">确认修改</a-button>
    </div>

  </a-form-model>

  </div>
</template>

<script>
  export default {
    name: 'SysMenuCreate',
    data () {
      return {
        item: { organ: {} },
        roles: [],
        menus: [{ id: '', name: '顶级', children: [] }],
        form: {
          layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
          },
          rules: {
            index: [{ required: true, message: '排序，从小到大' }],
            name: [{ required: true, message: '名称不可为空' }],
          },
          data: {
            parent_id: '', role_ids: [], index: '0', icon: '', name: '', target: '', remark: ''
          }
        }
      }
    },
    mounted() {
      let id = this.$route.params.id;
      this.$get('/sys/menu/change/' + id).then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          this.item = ajax.data.item;
          this.menus[0].children = ajax.data.menus;
          this.roles = ajax.data.roles;


          // 默认展开所有节点
          // this.menus.forEach((menu1)=>{
          //   if (menu1.children) {
          //     menu1.children.forEach((menu2)=>{
          //       if (menu2.children) {
          //         menu2.children.forEach((menu3)=>{
          //           menu3.selectable = false;
          //         })
          //       }
          //     })
          //   }
          // })


          for (let k in this.form.data) {
            if (ajax.data.item[k] && ajax.data.item[k].value) {
              this.form.data[k] = ajax.data.item[k].value;
            } else {
              if (ajax.data.item[k] !== null) {
                this.form.data[k] = ajax.data.item[k];
              }
            }
          }
        }
      })
    },

    methods: {
      onReset() {
        this.$refs.form.resetFields();
      },
      onSubmit() {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.change();
          }
        });

      },
      onSelect(keys, event) {
        this.menuKeys = keys;
        if (keys.length) {
          this.form.data.parent_id = keys[0];
        } else {
          this.form.data.parent_id = '';
        }
      },
      change (){
        let id = this.$route.params.id;
        this.$post('/sys/menu/change/'+id, this.form.data).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .powers h4 {
    margin: 0;
    height: 40px; line-height: 40px;
    border-bottom: solid 1px #eaeaea;
  }
  .powers table {
    line-height: 24px; width: 100%;
  }
  .powers tr {
    background-color: rgba(255,255,255,.025);
  }
  .powers th {
    width: 100px;
    padding: 12px; vertical-align: top;
  }
  .powers td {
    padding: 12px 4px;
  }

  .powers table tr:nth-of-type(even) {
    background-color: rgba(255,255,255,.025);
}
.powers table tr:nth-of-type(even) {
    background-color: #fafafa;
}

  .ant-card /deep/ .ant-card-body {
    padding: 10px 20px;
  }

</style>
